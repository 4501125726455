.orgs__container {
  min-height: 1000px;
}

.orgs__pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.mb-3{
  margin-bottom: 100px;
}