.resources__container {
  min-height: 1000px;
}

.resources__pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 35px;
}

.resources__filter-modal__label {
  padding-top: 10px;
}