.resources__container {
  min-height: 1000px;
}

.resources__pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.navflow-dashboard-container {
  margin-top: 80px;
}

.table-wrapper {
  // padding: 10px;

  table.navflow-dashboard {
    padding: 40px;
    border-radius: 10px;
    // background: rgb(232, 227, 227);
  }
  
  th {
    color: orange;
    padding: 10px;
  }
  
  tr {
    padding: 10px;
  }

  td {
    padding: 8px;
  }

  button {
    border: none;
    padding: 5px 15px 5px;
    border-radius: 5px;
  }

  button.finish-edit {
    border-radius: 0px;
  }

  .submittable-false {
    background: rgb(194, 191, 191);
    &:hover {
      background:rgb(141, 135, 135);
    }
  }

  .submittable-true {
    background: rgb(140, 140, 206);
    &:hover {
      background:rgb(110, 110, 172);
    }
  }

}